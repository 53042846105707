@import '@/styles/mixins.scss';

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background: var(--colors-mono-black-opacity-20);
}

.wrapper {
    @include flex-column;
    align-items: center;
    width: 388px;
    padding: 32px;
    gap: 24px;

    border-radius: 24px;
    background: var(--colors-mono-white-opacity-100, #fff);
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.2);

    position: absolute;
    top: calc(100% + 24px);
    left: 50%;
    transform: translateX(-50%);

    z-index: 10;

    transform-origin: center top;
}

.image {
    max-height: 120px;
}

.content {
    @include flex-column;
    align-items: center;
    gap: 12px;
}

.title {
    @include headline-3;
    color: var(--colors-gray-dark-70, #2e3136);
    text-align: center;
    margin: 0;
}

.description {
    @include text-S;
    color: var(--colors-gray-dark-40, #5b616b);
    text-align: center;
    margin: 0;
}

.button {
    width: 100%;
    height: 41px !important;
    border-radius: 8px !important;
    background: var(--colors-primary-dark-0, #1570ef);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
}

.close {
    position: absolute;
    top: 24px;
    right: 24px;

    cursor: pointer;

    svg {
        width: 24px;
        height: 24px;
        stroke: var(--colors-mono-black-opacity-20);

        transition: 150ms ease-out;
    }

    &:hover {
        svg {
            stroke: var(--colors-mono-black-opacity-60);
        }
    }
}

.animate-enter {
    opacity: 0.01;
    top: 20px;
    transform: translateX(-50%) scale3d(0, 0, 0);
}
.animate-enter-active {
    transition: 150ms ease-out;
    opacity: 1;
    top: calc(100% + 24px);
    transform: translateX(-50%) scale3d(1, 1, 1);
}
.animate-exit {
    opacity: 1;
    top: calc(100% + 24px);
    transform: translateX(-50%) scale3d(1, 1, 1);
}
.animate-exit-active {
    transition: 150ms ease-out;
    opacity: 0.01;
    top: 20px;
    transform: translateX(-50%) scale3d(0, 0, 0);
}

.overlay-animate-enter {
    background-color: transparent;
}
.overlay-animate-enter-active {
    transition: 150ms ease-out;
    background-color: var(--colors-mono-black-opacity-20);
}
.overlay-animate-exit {
    background-color: var(--colors-mono-black-opacity-20);
}
.overlay-animate-exit-active {
    transition: 150ms ease-out;
    background-color: transparent;
}

@media screen and (max-width: 960px) {
    .wrapper {
        transform: translateX(0);
        top: 72px;
        right: 20px;
        left: unset;

        transform-origin: calc(100% - 108px) top;

        &.extra-height {
            top: 116px;
        }
    }

    .animate-enter {
        opacity: 0.01;
        top: 50px;
        transform: translateX(0) scale3d(0, 0, 0);

        &.extra-height {
            top: 94px;
        }
    }
    .animate-enter-active {
        transition: 150ms ease-out;
        transition-delay: 100ms;
        opacity: 1;
        top: 72px;
        transform: translateX(0) scale3d(1, 1, 1);

        &.extra-height {
            top: 116px;
        }
    }
    .animate-exit {
        opacity: 1;
        top: 72px;
        transform: translateX(0) scale3d(1, 1, 1);

        &.extra-height {
            top: 116px;
        }
    }
    .animate-exit-active {
        transition: 150ms ease-out;
        opacity: 0.01;
        top: 50px;
        transform: translateX(0) scale3d(0, 0, 0);

        &.extra-height {
            top: 94px;
        }
    }
}

@media screen and (max-width: 836px) {
    .wrapper {
        transform-origin: calc(100% - 52px) top;
    }
}

@media screen and (max-width: 524px) {
    .wrapper {
        transform: translateX(0);
        bottom: 16px;
        right: 16px;
        left: 16px;
        top: unset;

        width: calc(100% - 32px);

        &.extra-height {
            top: unset !important;
        }
    }

    .animate-enter {
        opacity: 0.01;
        transform: translateX(0) translateY(40px);
    }
    .animate-enter-active {
        transition: 150ms ease-in-out;
        opacity: 1;
        transform: translateX(0) translateY(0);
    }
    .animate-exit {
        opacity: 1;
        transform: translateX(0) translateY(0);
    }
    .animate-exit-active {
        transition: 150ms ease-in-out;
        opacity: 0.01;
        transform: translateX(0) translateY(40px);
    }
}
